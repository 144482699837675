
/*

export interface PageSamples {
  temperature:number[];
  humidity:number[];
  co2:number[];
}

export type PageSampleIndex = "co2"|"humidity"|"temperature"

*/



/*
export interface GraphSetI{
  label:string;
  windows:PageSamples[][];
  timestamp:number;
  rate:number;
}


export class GraphSet{
  label:string;
  windows:PageSamples[][];
  timestamp:number;
  rate:number;
  index:number;
  color:RGBColor;


  constructor(d:GraphSetI){ //label:string,windows:PageSamples[][],timestamp:number,rate:number
    this.label = d.label;
    this.windows = d.windows;
    this.timestamp = d.timestamp;
    this.rate = d.rate;
    this.index = 0;
    this.color = [0,0,0];
  }

  getZoomValue(duration:number, width:number){
    
    for(let i = 0; i < this.windows.length; i++){
      //console.log( width, duration/this.rate * (2**i) );
        if( (duration / (this.rate * (2**i) ) ) < width){
          return i;
        }
    }

    return this.windows.length-1;
  }



  getDuration(){
    return this.windows[0].length*this.rate;
  }

  getStart(){
    return this.timestamp;
  }

  getEnd(){
    return this.timestamp+this.getDuration();
  }

  getDateRangeString(){
    let start = this.timestamp;
    let end = this.getEnd();

    return `${moment(start*1000).format("DD/MM/YY")} - ${moment(end*1000).format("DD/MM/YY")}`;
  }


  getValuesWithin(key:PageSampleIndex,domain:[number,number]){

    let start = this.getStart();
    let end = this.getEnd();
    let rate = this.rate * (2**0);
    let startIndex = Math.floor( (domain[0]-start)/rate );
    let endIndex = Math.floor( (domain[1]-start)/rate );
    let w = this.windows[0];

    if(startIndex < 0) startIndex = 0;
    if(endIndex > w.length ) endIndex = w.length;

    let values:number[] = [];
    for(let i = startIndex; i < endIndex; i++ ){
      let vs = w[i][key];
      for(let j = 0; j < vs.length; j++)
        values.push(vs[j]);
      //values = values.concat(w[i][key]);
    }
    return values;

}



  getStatsAtTime(key:PageSampleIndex,zoom:number,time:number):StatSample{

    let end = this.getEnd();
    let rate = this.rate * (2**zoom);


    if(time < this.timestamp){
      return getEmptyStat();
    }else if( time >= end){
      return getEmptyStat();
    }else{
        let i = Math.floor( (time-this.timestamp)/rate )
        if(typeof this.windows[zoom][i] != "undefined"){
          let values = this.windows[zoom][i];
          return calcStatSample(values[key]);
        }else{
          console.error(this.label,zoom,i+"/"+this.windows[zoom].length);
          return getEmptyStat();
        }
    }



  }

}




*/



export interface StatSample{
  median:number;
  mean:number;
  max:number;
  min:number;
  count:number;
  standardDeviation:number;
  upperQuartile:number;
  lowerQuartile:number;
  interQuartileRange:number;
  outliers:number[];
}



function getEmptyStat():StatSample{
return {
  outliers:[],
  upperQuartile:0,
  lowerQuartile:0,
  interQuartileRange:0,
  median:0,
  mean:0,
  max:0,
  min:0,
  standardDeviation:0,
  count:0
}
}



export function calculateStandardDeviation(values:number[]){
  let mean = meanCalc(values);
  let total = 0;
  for(let i = 0; i < values.length; i++){
    let value = values[i];
    total += ( Math.sqrt( (value - mean )**2 ) ) ** 2;
  }
  total /= (values.length-1)
  return Math.sqrt(total);
}


export function calcStatSample(values:number[],calcStandardDeviation:boolean=true):StatSample{
    let median = medianCalc(values);
    let mean = meanCalc(values);
    
    let [lower,upper] = upperLowerCalc(values); 
    let upperQuartile = medianCalc(upper);
    let lowerQuartile = medianCalc(lower);
    let interQuartileRange = upperQuartile-lowerQuartile
    

    let [inRange,outliers] = extractOutliers(values,lowerQuartile,upperQuartile,interQuartileRange);


    let max = maxNumbersCalc(inRange);
    let min = minNumbersCalc(inRange);

    let standardDeviation = 0;
    if(calcStandardDeviation)
      standardDeviation = calculateStandardDeviation(inRange);


    return {
        outliers:outliers,
        median: median,
        mean: mean,
        max: max,
        min: min,
        upperQuartile:upperQuartile,
        lowerQuartile:lowerQuartile,
        interQuartileRange:interQuartileRange,
        standardDeviation:standardDeviation,
        count: values.length
    };




}


export function extractOutliers(values:number[], lQ:number, uQ:number, IQR:number):[number[],number[]] {
  let outliers:number[] = [];
  let okay:number[] = [];

  if(values.length < 10){
    return [values,[]];
  }else{
  for(let i = 0; i < values.length; i++){
    let v = values[i];
    if( v < (IQR-(3*lQ/2)) ){
      outliers.push(v);
    } else if ( v > (IQR+(3*uQ/2)) ){
      outliers.push(v);
    }else{
      okay.push(v);
    }
  }

  // console.log(values,okay,outliers);

  return [okay,outliers];
}


}


export function upperLowerCalc(values:number[]){

  let s = values.sort( (a,b) => a-b );
  let i = Math.floor(s.length/2);
  if(s.length%2 == 1){
      return [ s.slice(0,i), s.slice(i+1)];
  }else{
      return [ s.slice(0,i), s.slice(i)];
  }

}

// let w:any = window;
// w.upperLower = upperLowerCalc;

export function minNumbersCalc(values:number[]){
  if(values.length == 0) 
    return 0;

  let v = values[0];

  for(let i = 0; i < values.length; i++){
    if(values[i] < v){
      v = values[i];
    }
  }

  return v;
}


export function maxNumbersCalc(values:number[]){
  if(values.length == 0) 
  return 0;

  let v = values[0];

  for(let i = 0; i < values.length; i++){
    //console.log(i, values.length);
    if(values[i] > v){
      v = values[i];
    }
  }

  return v;
}



export function medianCalc(values:number[]){
  if(values.length == 0) 
  return 0;

  let s = values.sort( (a,b) => a-b );
  let i = Math.floor(s.length/2);
  if(s.length%2 == 1){
      return s[i];
  }else{
      return (s[i-1]+s[i])/2
  }
}



export function meanCalc(values:number[]){
  if(values.length == 0) 
    return 0;

 let total = 0;

 for(let i = 0; i < values.length; i++)
    total += values[i];

  return total/values.length;

}


/*

export function flattenStatistics(csv:any,startTime:number,endTime:number,rate:number,setProgress?:(index:number,count:number)=>void){


    let i = 0;
    let len = csv.length;

    //create seed stream @ rate
    let samples:PageSamples[] = [];
    

  let j = 0;
  let count = Math.round((endTime-(startTime+rate))/rate);
  
    for(let t = startTime+rate; t < endTime; t += rate){
      let page:PageSamples = {
        co2:[],
        humidity:[],
        temperature:[]
      }
      
      for(i; i < len; i++){
        let row = csv[i]
        if( row.timestamp <= t ){
          page.temperature.push(row.temperature);
          page.humidity.push(row.humidity);
          page.co2.push(row.co2);
        }else{
          break;
        }
      }

      //if(page.length > 0)
      samples.push(page);//calcStatSample(page));
      //else
        //samples.push(0);

      if(setProgress)
        setProgress(j,count);
    }

    //console.log(samples)
    return samples;
}

*/