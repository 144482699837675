import { Card, CardActionArea, CardActions, CardContent, IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import { generateColor, rgbToStr } from "../lib/Color";
import { Remove } from "@mui/icons-material";

import { useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { importFilesForWorker } from "../lib/importFilesWorker";
import { GraphSetArrayBuffer } from "../lib/ArrayBufferChart";

interface DataSourceTableI{
    concatSets:(sets:GraphSetArrayBuffer[])=>void;
    removeSet:(index:number)=>void;
    sets:GraphSetArrayBuffer[];
}

export function DataSourceTable(props:DataSourceTableI){
    const {concatSets,removeSet,sets} = props;

    const [loading,setLoading] = useState(100);

    function add(){
        importFilesForWorker(concatSets,setLoading);
    }


    return <Card>
        <LinearProgress variant="determinate" value={loading} />

        <CardContent>
            Data sources
            <br/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Color</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Controls</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sets.map( (set,i) => {
                        return <TableRow key={i}>
                            <TableCell><Remove style={{fill:rgbToStr(set.color)}} /></TableCell>
                            <TableCell>{set.label}</TableCell>
                            <TableCell>{set.getDateRangeString()}</TableCell>
                            <TableCell><IconButton color="error" size="small" onClick={() => removeSet(i) }><ClearIcon /></IconButton></TableCell>
                        </TableRow>})}
                </TableBody>
            </Table>





        </CardContent>
        <CardActions>
            <IconButton color="primary" onClick={add} ><AddIcon /></IconButton>
        </CardActions>
        
    </Card>


}