import { Card, CardActionArea, CardActions, CardContent, Checkbox, FormControlLabel, Grid, IconButton, Input, LinearProgress, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import { generateColor } from "../lib/Color";
import { Remove } from "@mui/icons-material";
import React, { useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { ChartSettingsI, validateChartSetting } from "./Chart";
import moment from "moment";
import { GraphSetArrayBuffer } from "../lib/ArrayBufferChart";

interface ChartSettingsCardI{
    setConfigs:(config:ChartSettingsI[])=>void;
    configs:ChartSettingsI[];
    sets:GraphSetArrayBuffer[];
}

export function ChartSettingsCard(props:ChartSettingsCardI){
    const {sets,configs,setConfigs} = props;


    function changeSettingApply(s:any,e:any){
        
        
        
        //let s:any = {...config};
        let n = e.target.name;
        let type = e.target.type;
        
        if(type == "checkbox"){
            s[n] = e.target.checked;
        }else if(type == "date"){
            let v = Math.round(moment(e.target.value,"YYYY-MM-DD").valueOf()/1000);
            // console.log(v);
            if(v == undefined || isNaN(v))
                v = 0;
            if(n == "domain[0]")
                s.domain[0] = v;
            else if(n == "domain[1]")
                s.domain[1] = v;
            else
                console.error("not matching")
        }else if(type == "number"){
            let v = parseFloat(e.target.value);
            if(n == "range[0]")
                s.range[0] = v;
            else if(n == "range[1]")
                s.range[1] = v;
            else
                console.error("not matching")
        }else if(type == "radio"){
            s[n] = e.target.value=="true";
        }else{
            console.error(type+" type not supported");
        }

//        setConfig(s);

    }



    function changeAllSetting(e:any){
        for(let i = 0; i < configs.length; i++)
            changeSettingApply(configs[i],e);
        setConfigs(configs);
    }

    function changeSensorSetting(e:any,i:number){
        changeSettingApply(configs[i],e);
        setConfigs(configs);
    }


    function testRange(sensor:string,key:string,validation:any){
        if(typeof validation.sensors == "undefined") return false;
        else if(typeof validation.sensors[sensor] == "undefined") return false;
        else if(typeof validation.sensors[sensor][key] == "undefined") 
            return false;
        else 
            return true;


        
    }

    let config = configs[0];
    let validation = validateChartSetting(config);
    let sensors = ["Temperature","Humidity","CO2"];

    return <Card>
        <CardContent>
            Chart settings
            <br/>
            <br/>
          
            <Grid container spacing={4}>

                <Grid item xs={4}>
                    Timestamp Domain: 
                    <RadioGroup name="automaticDomain" row onChange={changeAllSetting}  >
                        <FormControlLabel value={true} control={<Radio />} label="Automatic" checked={config.automaticDomain}  />
                        <FormControlLabel value={false} control={<Radio />} label="Manual" checked={!config.automaticDomain} />
                    </RadioGroup>
                </Grid>
                <Grid item xs={4}><TextField name="domain[0]" error={"domain" in validation} helperText={validation["domain"]} label="Start Date" type="date" InputLabelProps={{ shrink: true }} fullWidth onChange={changeAllSetting} disabled={config.automaticDomain} value={config.domain[0]==0?"":(moment(config.domain[0]*1000).format("YYYY-MM-DD"))} /> </Grid>
                <Grid item xs={4}><TextField name="domain[1]" error={"domain" in validation}  helperText={validation["domain"]} label="End Date" type="date" InputLabelProps={{ shrink: true }} fullWidth  onChange={changeAllSetting} disabled={config.automaticDomain}  value={config.domain[1]==0?"":(moment(config.domain[1]*1000).format("YYYY-MM-DD"))} /> </Grid>
                   
                <Grid item xs={12}>
                    Sensors:
                </Grid>

                {sensors.map((sensor,i) => {
                    let s = configs[i];

                    return <React.Fragment key={sensor}>
                        <Grid item xs={2}>
                            <div>{sensor}:</div>
                             <FormControlLabel control={<Checkbox  />} name="plot" label="Plot" onChange={(e:any) => changeSensorSetting(e,i)} checked={s.plot} /> 
                        </Grid>

                        <Grid item xs={4}>
                            Value Range: 
                            <RadioGroup name="automaticRange" row onChange={(e) => changeSensorSetting(e,i)}  >
                                <FormControlLabel value={true} control={<Radio />} label="Automatic" checked={ s.automaticRange }    />
                                <FormControlLabel value={false} control={<Radio />} label="Manual" checked={ !s.automaticRange }  />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={3}><TextField name="range[0]" error={testRange(sensor,"range",validation)}  label="Start Value" type="number" InputLabelProps={{ shrink: true }} fullWidth  onChange={(e) => changeSensorSetting(e,i)}  disabled={s.automaticRange} value={(s.range[0])} /> </Grid>
                        <Grid item xs={3}><TextField name="range[1]"  error={testRange(sensor,"range",validation)}  label="End Value" type="number" InputLabelProps={{ shrink: true }} fullWidth   onChange={(e) => changeSensorSetting(e,i)}  disabled={s.automaticRange}  value={(s.range[1])}  /> </Grid>
                    </React.Fragment>
                })}  


  
                <Grid item xs={12}>
                    Statistics:&nbsp;&nbsp;&nbsp;
                    <FormControlLabel control={<Checkbox  />} name="enableMinMax" label="Min and Max" onChange={changeAllSetting} checked={config.enableMinMax}  />        
                    <FormControlLabel control={<Checkbox  />} name="enableMean" label="Mean" onChange={changeAllSetting} checked={config.enableMean}  /> 
                    <FormControlLabel control={<Checkbox  />} name="enableMedian" label="Median" onChange={changeAllSetting} checked={config.enableMedian}  /> 
                    <FormControlLabel control={<Checkbox  />} name="enableOutliers" label="Outliers" onChange={changeAllSetting} checked={config.enableOutliers}  /> 
                </Grid>

                <Grid item xs={12}>
                    Guides:&nbsp;&nbsp;&nbsp;
                    <FormControlLabel control={<Checkbox  />} name="enableCO2Ranges" label="CO2 ranges" onChange={(e) => changeSensorSetting(e,2)} checked={configs[2].enableCO2Ranges}  /> 
                </Grid>
            
            </Grid>

            {/*<pre>{JSON.stringify(config,null,3)}</pre>*/}
        </CardContent>
 
        
    </Card>


}


/*

              {sensors.map(sensor => {
                    let s = config.sensors[sensor];

                    return <React.Fragment key={sensor}>
                        <Grid item xs={2}>
                            <div>{s.title}:</div>
                             <FormControlLabel control={<Checkbox  />} name="plot" label="Plot" onChange={(e:any) => changeSensorSetting(sensor,e)} checked={s.plot} /> 
                        </Grid>

                        <Grid item xs={4}>
                            Value Range: 
                            <RadioGroup name="automaticRange" row onChange={changeSetting}  >
                                <FormControlLabel value={true} control={<Radio />} label="Automatic" checked={ s.automaticRange }  onChange={(e:any) => changeSensorSetting(sensor,e)}  />
                                <FormControlLabel value={false} control={<Radio />} label="Manual" checked={ !s.automaticRange }  onChange={(e:any) => changeSensorSetting(sensor,e)} />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={3}><TextField name="range[0]" error={testRange(sensor,"range",validation)}  label="Start Value" type="number" InputLabelProps={{ shrink: true }} fullWidth  onChange={(e) => changeSensorSetting(sensor,e)}  disabled={s.automaticRange} value={(s.range[0])} /> </Grid>
                        <Grid item xs={3}><TextField name="range[1]"  error={testRange(sensor,"range",validation)}  label="End Value" type="number" InputLabelProps={{ shrink: true }} fullWidth   onChange={(e) => changeSensorSetting(sensor,e)}  disabled={s.automaticRange}  value={(s.range[1])}  /> </Grid>
                    </React.Fragment>
                })}             

    */



/*


   <Grid item xs={4}>
                    Value Range: 
                    <RadioGroup name="automaticRange" row onChange={changeSetting}  >
                        <FormControlLabel value={true} control={<Radio />} label="Automatic" checked={config.automaticRange}  />
                        <FormControlLabel value={false} control={<Radio />} label="Manual" checked={!config.automaticRange} />
                    </RadioGroup>
                </Grid>

                <Grid item xs={4}><TextField name="range[0]" label="Start Value" type="number" InputLabelProps={{ shrink: true }} fullWidth onChange={changeSetting} disabled={config.automaticRange} value={(config.range[0])} /> </Grid>
                <Grid item xs={4}><TextField name="range[1]" label="End Value" type="number" InputLabelProps={{ shrink: true }} fullWidth  onChange={changeSetting} disabled={config.automaticRange}  value={(config.range[1])}  /> </Grid>
            

                

    */