

export async function importJSON(file:File){
	let text = await readFileText(file)
	return JSON.parse(text);
}




export async function importCSV(file:File){
	let text = await readFileText(file)
	let table = await readCSVFile(text);
	let data = translateCSVFile(table);
	return data;
}

export async function translateCSVFile(table:any){
	let header:string[] = table.shift(); // remove header
	const body = table;

	header = header.map( h => h.trim() )

	let data:any = [];
	for(let j = 0; j < body.length; j++){
		let row = body[j];
		let obj:any = {};

		if(row.length != header.length){
			console.error("CSV row does not match header size");
			continue;
		}
		
		for(let i = 0; i < header.length; i++){
			let n = header[i];
			let v = row[i];
			obj[n] = v;
		}
		data.push(obj);
	}
	return data;
}



export async function readFileText(file:File):Promise<string>{
	return new Promise((resolve,reject) => {
		const reader = new FileReader();
		
		reader.onload = (e:any) => {
			var d = e.target.result.toString("utf-8");
			d = d.replace(/\xA0/g," ");
			resolve(d);
		};
		
		reader.onerror = function(error:any) {
			reject(error);
		}

		reader.readAsText(file);
	});

}



export async function readCSVFile(csv:string){
	return new Promise((resolve,reject) => {
		csv = csv.replace(/\xA0/g," ");
		let rows = csv.split("\n");
		if(rows[rows.length-1] == "") rows.pop();
		// console.log("start");
		let header:any = (rows.shift()?.split(",").map( c => JSON.parse(c)) );
		let cols:any[] = [];
		for(let i = 0; i < rows.length; i++){
			try{cols.push(JSON.parse(`[${rows[i]}]`));}catch(E){console.error("CSV parse error",E);}
		}
		
		
		//rows.map( row => row.split(",").map( cell => parseFloat(cell) ) );
		// console.log("end");
		
		let c = [];
		c.push(header);
		c = c.concat(cols);

		resolve(c);
	});
}




