import { Card, CardActionArea, CardActions, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Input, LinearProgress, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';

import { generateColor, rgbToStr } from "../lib/Color";
import { Remove } from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { ChartSettingsI } from "./Chart";
import moment from "moment";
import { GraphSetArrayBuffer, ProcessedGraphView, processGraphConfig, StatArrayEnum } from "../lib/ArrayBufferChart";

interface StatisticsTableI{
    sensorIndex:number;
    config:ChartSettingsI;
    sets:GraphSetArrayBuffer[];
    setHash:number;
}

export function StatisticsTable(props:StatisticsTableI){
    const {sensorIndex,sets,config,setHash} = props;



    const [loading,setLoading] = useState(true);
    const [view,setView] = useState<ProcessedGraphView|null>(null)



    useEffect( () => {
        if(sets.length > 0){
          // console.log(`processing buffer at statistic ${moment(config.domain[1]*1000).format("DD/MM/YY")}`);
          setLoading(true);
          let processed = processGraphConfig(sensorIndex,sets,config.domain,1);
        //   console.log(processed);
          setView(processed);
          setLoading(false);
        }else{
          setView(null);
        }
      },[setHash]);
    


    if(view == null)
      return null;
    else   if(loading){
      return <div><CircularProgress color="primary" /></div>
    }else
    return <Card>
        <CardContent>
            Statistics for {config.title} over {moment(config.domain[0]*1000).format("DD/MM/YY")} to {moment(config.domain[1]*1000).format("DD/MM/YY")} 
            <br/>
            <br/>
          
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Color</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Mean</TableCell>
                        <TableCell>Median</TableCell>
                        <TableCell>Min</TableCell>
                        <TableCell>Max</TableCell>
                        <TableCell>Standard Deviation</TableCell>
                        {/*<TableCell>Outlier count</TableCell>*/}
                        <TableCell>Total Samples</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...Array(view.setCount)].map( (v,i) => {
                        
                        //let values = set.getValuesWithin(sensorKey,config.domain);
                        //let stat = calcStatSample(values,true);
                        let f = (attr:number) => view.getStatsTime(0,i, attr);
                        

                        return <TableRow key={i}>
                            <TableCell><Remove style={{fill:rgbToStr(view.colors[i])}} /></TableCell>
                            <TableCell>{view.labels[i]}</TableCell>
                            <TableCell>{f(StatArrayEnum.mean).toFixed(2)}</TableCell>
                            <TableCell>{f(StatArrayEnum.median).toFixed(2)}</TableCell>
                            <TableCell>{f(StatArrayEnum.min).toFixed(2)}</TableCell>
                            <TableCell>{f(StatArrayEnum.max).toFixed(2)}</TableCell>
                            <TableCell>{f(StatArrayEnum.standardDeviation).toFixed(2)}</TableCell>
                            {/*<TableCell>{stat.outliers.length}</TableCell>*/}
                            <TableCell>{f(StatArrayEnum.count)}</TableCell>
                        </TableRow>})}
                </TableBody>
            </Table>




        </CardContent>
 
        
    </Card>


}
