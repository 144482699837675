import moment from "moment";
import { ChartSettingsI } from "../ui/Chart";
import { RGBColor } from "./Color";
import { readFileText } from "./CSV";
import { uploadFiles } from "./File";
import { FileStringI } from "./importFilesWorker";
import { calcStatSample, medianCalc } from "./Statistic";
import { testArrayBufferWorker } from "./workers";


const MAGICNULL = Infinity;


export class GraphMaster{

  sets:GraphSetArrayBuffer[];


  constructor(){
    this.sets = [];
  }

  concatSets(new_sets:GraphSetArrayBuffer[]){
    this.sets = this.sets.concat(new_sets);
  }


  async upload(setProgress:(v:number)=>void){

    setProgress(0);
    try{
        let files = await uploadFiles(".csv");
        let data:FileStringI[] = [];
        for(let i = 0; i < files.length; i++){
            let file = files[i];
            let text = await readFileText(file)
            data.push({name:file.name,data:text})
        }

        let abs = [];

        for(let file of data){
          abs.push(await testArrayBufferWorker(file,setProgress));
        }

        let gs = abs.map( ab => new GraphSetArrayBuffer(ab)  );
        this.concatSets(gs);
        setProgress(100);
    }catch(E){
      console.error(E);
    }
    
  }

  async processGraphConfig(sensorIndex:number,config:ChartSettingsI){
    return processGraphConfig(sensorIndex,this.sets,config.domain,config.width);
  }

}





export interface GraphSetArrayBufferI{
    label:string;
    rate:number;
    timestamp:number;
    samples:ArrayBuffer;
    sensorCount:number;
  }
  
  
  export class GraphSetArrayBuffer{
    label:string;
    samples:Float32Array;
    timestamp:number;
    rate:number;
    index:number;
    color:RGBColor;
    sensorCount:number;
  
    constructor(d:GraphSetArrayBufferI){ 
      this.label = d.label;
      this.samples = new Float32Array(d.samples);
      this.timestamp = d.timestamp;
      this.rate = d.rate;
      this.sensorCount = d.sensorCount;
      this.index = 0;
      this.color = [0,0,0];
    }

 
    getDuration(){
      return this.samples.length/this.sensorCount*this.rate;
    }
  
    getStart(){
      return this.timestamp;
    }
  
    getEnd(){
      return this.getStart()+this.getDuration();
    }
  
    getDateRangeString(){
      let start = this.getStart();
      let end = this.getEnd();
  
      return `${moment(start*1000).format("DD/MM/YY")} - ${moment(end*1000).format("DD/MM/YY")}`;
    }
  
  
  
  }
  
  
  
export function importRow(row:any){
  return {
    timestamp:parseInt(row.time),
    temperature:parseFloat(row.temperature),
    humidity:parseFloat(row.humidity),
    co2:parseFloat(row.co2)
  };
}
export function validateRow(row:any){
  let keys = ["timestamp","temperature","humidity","co2"];
  for(let key of keys){
    if(!(key in row) || typeof row[key] == undefined)
      return false;
    if(isNaN(row[key]))
      return false
  }
  return true;
}

  
  export function processSamplesArrayBuffer(csv:any[]):[number,number,Float32Array]{
  
    let rows:any[] = [];

    // console.log(csv);
  
    for(let i = 0; i < csv.length; i++){
      let row = importRow(csv[i])
      if(validateRow(row))
        rows.push(row);

    }
  
    let rate = 60; //1 min
    let startTime = rows[0].timestamp;
    let endTime = rows[rows.length-1].timestamp+rate;
    let diff = endTime-startTime;
    
    let count = Math.floor(diff/rate);
    let samples = new Float32Array(count*3);
  
  
    let i = 0;
    let len = csv.length;
  
    
    let j = 0;
    for(let t = startTime+rate; t < endTime; t += rate){
      let page:any = {
        co2:[],
        humidity:[],
        temperature:[]
      }
      
      for(i; i < len; i++){
        let row = rows[i];
        //console.log(row.timestamp);
        if( row.timestamp <= t ){

          //console.log(row);
          page.temperature.push(row.temperature);
          page.humidity.push(row.humidity);
          page.co2.push(row.co2);
        }else{
          break;
        }
      }

      if(page.temperature.length > 0){
        samples[j+0] = medianCalc(page.temperature);
        samples[j+1] = medianCalc(page.humidity);
        samples[j+2] = medianCalc(page.co2);
      }else{
        samples[j+0] = MAGICNULL;
        samples[j+1] =  MAGICNULL;
        samples[j+2] =  MAGICNULL;
      }
  
      j += 3;
    }

    return [startTime,rate,samples];
  }
  
  export class ProcessedGraphView{
    buffer:Float32Array;
    outliers:Float32Array;
    width:number;
    setCount:number;
    labels:string[];
    colors:RGBColor[];
    domain:[number,number];

    constructor(buffer:Float32Array,domain:[number,number],width:number,setCount:number,labels:string[],colors:RGBColor[],outliers:Float32Array){
      this.buffer = buffer;
      this.domain = domain;
      this.width = width;
      this.setCount = setCount;
      this.colors = colors;
      this.labels = labels;
      this.outliers = outliers;
    }


    getStatsTime(t:number, setIndex:number,statIndex:number){
      return getStatFloat32(this.buffer,t, this.width, this.setCount, setIndex, statIndex);
    }

    getOutliers(t:number, setIndex:number){
      let index = this.getStatsTime(t,setIndex,StatArrayEnum.outlierIndex);
      let length = this.getStatsTime(t,setIndex,StatArrayEnum.outlierLength);

      if(length == 0){
        return [];
      }else{
          return this.outliers.slice(index,index+length);
      }

    }
  }
  
  export function processGraphConfig(sensorIndex:number,sets:GraphSetArrayBuffer[],domain:[number,number],nWidth:number){
    //console.log("processGraphConfig",sensorIndex,sets,domain[0],domain[1]);

    let width = Math.floor(nWidth);
    let duration = domain[1] - domain[0];
    let timeChunks = duration / width;

    if(timeChunks < 120){
      timeChunks = 120;
      width = Math.floor(duration/timeChunks);
    }

  
    let statCount = StatArrayEnum.length;
    let outliers = [];
    let buffer = new Float32Array(width*sets.length*statCount);
  
    for(let setIndex = 0; setIndex < sets.length; setIndex++){
      let set = sets[setIndex];
      let count = set.samples.length/3

      for(let i = 0; i <  width; i++){
        let t0 = i*timeChunks + domain[0];
        let t1 = (i+1)*timeChunks + domain[0];
        let i0 = Math.floor((t0 - set.timestamp)/set.rate);
        let i1 = Math.floor((t1 - set.timestamp)/set.rate);
        
        let values = [];
        for(let j = i0; j < i1; j++){
          if(j >= 0 && j < count){
            let v = set.samples[j*3+sensorIndex];
            if(v != MAGICNULL)
            values.push(v);
          }
        }

        let chunkOutliers = writeStatFloat32(values,buffer,i,width,sets.length,setIndex,outliers.length);
        for(let o = 0; o < chunkOutliers.length; o++)
          outliers.push(chunkOutliers[o]);

      }

    }

    let labels = sets.map(s => s.label);
    let colors = sets.map(s => s.color);


    let floatOutliers = new Float32Array(outliers);
   

    let processed = new ProcessedGraphView(buffer,domain,width,sets.length,labels,colors,floatOutliers);

    return processed;
  
  }
  
  export enum StatArrayEnum{
    median,
    mean,
    max,
    min,
    count,
    standardDeviation,
    upperQuartile,
    lowerQuartile,
    interQuartileRange,
    outlierIndex,
    outlierLength,
    length
  }
  
  
  export function writeStatFloat32(samples:number[],buffer:Float32Array,index:number,width:number,setCount:number,setIndex:number,outlierIndex:number){
    let stat = calcStatSample(samples);
  
    let i = ( setIndex  * width + index ) * StatArrayEnum.length 
  
    buffer[  i + StatArrayEnum.median ] = stat.median;
    buffer[  i + StatArrayEnum.mean ] = stat.mean;
    buffer[  i + StatArrayEnum.max ] = stat.max;
    buffer[  i + StatArrayEnum.min ] = stat.min;
    buffer[  i + StatArrayEnum.count ] = stat.count;
    buffer[  i + StatArrayEnum.standardDeviation ] = stat.standardDeviation;
    buffer[  i + StatArrayEnum.upperQuartile ] = stat.upperQuartile;
    buffer[  i + StatArrayEnum.lowerQuartile ] = stat.lowerQuartile;
    buffer[  i + StatArrayEnum.interQuartileRange ] = stat.interQuartileRange;
    buffer[  i + StatArrayEnum.outlierIndex ] = outlierIndex;
    buffer[  i + StatArrayEnum.outlierLength ] = stat.outliers.length;

    return stat.outliers;
  }
  
  
  export function getStatFloat32(buffer:Float32Array,index:number,width:number,setCount:number,setIndex:number,statIndex:StatArrayEnum){
    
    let i = ( setIndex *  width + index ) * StatArrayEnum.length 
    let v = buffer[  i + statIndex ];

    return v;
  }
  