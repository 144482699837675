/* eslint import/no-webpack-loader-syntax: off */
//import Worker from "worker-loader!../Worker";
//import { StatisticSeries } from "./Statistic";
//import { ssFromDistribution } from "./Distribution";

import { FileStringI } from "./importFilesWorker";
import { GraphSetArrayBufferI } from "./ArrayBufferChart";

/*

export interface ProcessResult{
    data: number;
    index:number;
    total:number;
  }
  

export async function testWorker(data:FileStringI[],updateProgress:(progress:number)=>void):Promise<GraphSetI[]>{
    return new Promise( (resolve,reject) => {
        //var sab = new ArrayBuffer(1024);

        const worker = new Worker(new URL('../Worker.ts', import.meta.url));
        worker.postMessage(data);
        worker.onmessage = (event) => {};
        worker.addEventListener("message", (event) => {
            //console.log("event",event.data)
            let d:any = event.data;
            //d.data = [d.data,0,1];
            //let dd = d as ProcessResult;

            if("result" in d){
                console.log("resolving result");
                resolve(d.result);
            }else if("progress" in d)
                updateProgress(d.progress);

        });
    });
}

*/


export async function testArrayBufferWorker(data:FileStringI,updateProgress:(progress:number)=>void):Promise<GraphSetArrayBufferI>{
    return new Promise( (resolve,reject) => {
        
        let meta:any = {};
        const worker = new Worker(new URL('../ArrayBufferWorker.ts', import.meta.url));
        worker.postMessage(data);
        worker.onmessage = (event) => {};
        worker.addEventListener("message", (event) => {
            let d:any = event.data;
            if("progress" in d){
                updateProgress(d.progress);
            }else if("timestamp" in d){
                meta = d;
            }else{
                // console.log("resolving result");
                // console.log(typeof d);
                // console.log(d);
                let pkg = {
                    label:data.name,
                    rate:meta.rate,
                    timestamp:meta.timestamp,
                    samples:d,
                    sensorCount:3
                }
                resolve(pkg);
            }

        });
    });
}