import { Button, Card, CardContent, CardHeader, Grid, setRef } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { importCSV } from './lib/CSV';
import { uploadFile, uploadFiles } from './lib/File';
import { RangeI, Chart, ChartSettingsI, validateChartSetting } from './ui/Chart';
import { DataSourceTable } from './ui/DataSourceTable';
import {Header} from "./ui/Header";

//import { GraphSet, GraphSetArrayBuffer, PageSampleIndex} from "./lib/Statistic";
import { ChartSettingsCard } from './ui/ChartSettingsCard';
import { generateColor,RGBColor } from './lib/Color';
import { StatisticsTable } from './ui/StatisticsTable';
import { GraphSetArrayBuffer, processGraphConfig, StatArrayEnum } from './lib/ArrayBufferChart';



function App() {

  const styles = {
    container:{
      padding:20
    }
  };


  const timeoutRef = useRef<any|null>(null);

  const setHashRef = useRef(0);
  const setsRef = useRef<GraphSetArrayBuffer[]>([]);



  function timeoutRender(){
    if(timeoutRef.current != null) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      reprocess();
    },250);


  }



  let createConfig = ():ChartSettingsI => ({
    title:"",
    rangeLabel:"",
    plot:true,
    automaticRange:true,
    range:[0,2000],
    width:0,
    height:0,
    automaticDomain:true,
    domain:[0,0],
    domainLabel:"Time",
    enableMinMax:true,
    enableMean:false,
    enableMedian:true,
    enableOutliers:true,
    enableCO2Ranges:false,
    gridX:5,
    gridY:10
  })

  function createConfigs(){
    let configs:ChartSettingsI[] = [];

    for(let i = 0; i < 3; i++)
      configs.push(createConfig());

      configs[0].title = "Temperature"
      configs[0].rangeLabel = "Temperature (°C)"
      configs[0].range = [0,50]

      configs[1].title = "Humidity"
      configs[1].rangeLabel = "Humidity (%RH)"
      configs[1].range = [0,100]

      configs[2].title = "CO2"
      configs[2].rangeLabel = "CO2 (ppm)"
      configs[2].range = [0,2000]
      configs[2].enableCO2Ranges = true;



      return configs;
  }



  const configsRef = useRef<ChartSettingsI[]>(createConfigs());



  function increaseSetHash(){
    setHashRef.current++;
  }



  function setConfigs(prop:ChartSettingsI[]){
    configsRef.current = prop;
    reprocess();
  }

  const [, updateState] = useState<any>();
  const forceRender = useCallback(() => updateState({}), []);

  const parentDivRef = useRef<HTMLDivElement|null>(null);


  // function setParentDiv(v:HTMLDivElement|null){
  //   parentDivRef.current = v;
  // }

  


  function resizeGraphs(){
    let parentDiv = parentDivRef.current;
    if(parentDiv != null){
      let width = Math.floor(parentDiv.getBoundingClientRect().width);
        let height = Math.floor(width*0.5);  
        
        for(let i = 0 ; i < configsRef.current.length; i++){
          configsRef.current[i].width = width;
          configsRef.current[i].height = height;
        }

        // console.log(`rendering with new dimensions ${width}x${height}`);
    }
  }

  useEffect(() => {
    let f = () => {  /*console.log("resize");*/ timeoutRender(); }
    let event = window.addEventListener('resize', f);
    return () => window.removeEventListener('resize',f);
  }, []);


  function processDomain(){
    if(setsRef.current.length == 0 ) return;
    
    let configs = configsRef.current;
    let config = configs[0];

    if( config != null && (config.automaticDomain || (config.domain[0] == 0 || config.domain[1] == 0 )) )
      setAutomaticDomain();
  }


  function setAutomaticDomain(){
    let sets = setsRef.current;
    let starts = sets.map( set => set.getStart() );
    let ends = sets.map( set => set.getEnd() );
    let max = Math.max(...ends);
    let min = Math.min(...starts);
    for(let i = 0; i < configsRef.current.length; i++){
      configsRef.current[i].domain = [min , max ];
    }
  }


    function processRange(){
      let sets = setsRef.current;
      if(sets.length == 0 ) return;
      let configs = configsRef.current;

      for(let i = 0; i < configs.length; i++){
        let config = configs[i];
        if( config.automaticRange || (config.range[0] == 0 && config.range[1] == 0 ) ) 
          setAutomaticRange(i);
      }
  }



  function setAutomaticRange(sensorIndex:number){
    // console.log("setAutomatic range")
    
    let configs = configsRef.current;
    let config = configs[0];

    let width = config.width;
    let duration = (config.domain[1] - config.domain[0]);
    if(duration == 0)
      return;

    let min = Infinity
    let max = -Infinity;

    let view = processGraphConfig(sensorIndex,sets,config.domain,1);

    for(let i = 0; i < sets.length; i++){
      let f = (attr:number) => view.getStatsTime(0,i, attr);
        if(f(StatArrayEnum.count) > 0){

          if(config.enableMinMax){
            if( f(StatArrayEnum.min) < min)
              min = f(StatArrayEnum.min);
            
            if( f(StatArrayEnum.max) > max)
              max = f(StatArrayEnum.max);
          }

            if(config.enableMean){
              let mean = f(StatArrayEnum.mean)
              if( mean < min )
                min = mean;
              
              if( mean > max )
                max = mean;
            }


            if(config.enableMedian){
              let median = f(StatArrayEnum.median)
              if( median < min )
                min = median;
              
              if( median > max )
                max = median;
            }

            if(config.enableOutliers){
              let outliers = view.getOutliers(0,i);

              for(let j = 0; j < outliers.length; j++){
                let o = outliers[j];
                if( o < min )
                min = o;
              
                if( o > max )
                  max = o;
              }


            
            }

          }



      }

      

    configsRef.current[sensorIndex].range = [ Math.floor(min), Math.ceil(max) ]; // [min,max]; //
  }




  function recalculateIndexColors(){
    let sets = setsRef.current;
      let nSets = [...sets];

    for(let i = 0; i < nSets.length; i++){
      nSets[i].index = i;
      nSets[i].color = generateColor(i,sets.length);
    }

  }

  function concatSets(new_sets:GraphSetArrayBuffer[]){
    // console.log("concatSets",new_sets.length);
    setsRef.current.push(...new_sets);
    reprocess();
  }


  function removeSet(index:number){
    setsRef.current.splice(index,1);    
    reprocess();
  }


  function reprocess(){
    resizeGraphs();
    processDomain();
    processRange();
    recalculateIndexColors();
    increaseSetHash();
    // console.log("reprocessed domains and rerender")
    forceRender();
    // console.log("rendered");
  }



    let co2Bands:RangeI[] = [
      {start:1500,end:1000000,color:[255,0,0],opacity:0.2},
      {start:800,end:1500,color:[255,255,0],opacity:0.2},
      {start:0,end:800,color:[0,255,0],opacity:0.2}
    ];
  

  let configs = configsRef.current;
  let sets = setsRef.current
  let setHash = setHashRef.current;

  


//(div) => {setParentDiv(div);}

  return <div>
    <Header />
    <div style={styles.container}>

      <Grid container spacing={2}>


        <Grid item xs={12}>
          <DataSourceTable concatSets={concatSets} removeSet={removeSet} sets={sets} />
        </Grid>


       


         <React.Fragment>

        <Grid item xs={12}>
          <ChartSettingsCard configs={configs} setConfigs={setConfigs} sets={sets} />
        </Grid>

      
        {[2,0,1].map(sensor => {
          let valid = Object.keys(validateChartSetting(configs[sensor])).length == 0;
          let config = configs[sensor];
          if(config.plot)
          return <React.Fragment key={"sensor-"+sensor}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div ref={parentDivRef} style={{height:config.height}}>
                  {(valid)?<Chart setHash={setHash} sensorIndex={sensor} sets={sets} config={config} ranges={config.enableCO2Ranges?co2Bands:[]} />:null}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {(valid)?<StatisticsTable setHash={setHash}  sensorIndex={sensor}  config={config} sets={sets} />:null}
            </Grid>
       </React.Fragment>
       else
       return null;

      })}  


        </React.Fragment>

     

      </Grid>

      </div>
  </div>
}


export default App;

