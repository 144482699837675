import { Card, CardContent, CircularProgress } from "@mui/material";
import moment from "moment";
import React, { PureComponent, useRef, useState, useCallback, useEffect  } from 'react';
import { GraphSetArrayBuffer, ProcessedGraphView, processGraphConfig, StatArrayEnum } from "../lib/ArrayBufferChart";
import { generateColor, RGBColor, rgbToStr, rgbToStrAlpha } from "../lib/Color";




export function validateChartSetting(config:ChartSettingsI){
  let v:any = {};

  
  if(config.width == 0)
    return {width:"width is 0"};

  if(config.height == 0)
    return {height:"height is 0"};
    

    if( config.domain[0] >= config.domain[1] )
    v.domain = "domain is incorrect";

    if( config.range[0] >= config.range[1] )
      v.range = "range is incorrect";

  return v;
}



export interface ChartI{
  sets:GraphSetArrayBuffer[];
  config:ChartSettingsI;
  ranges:RangeI[];
  sensorIndex:number;
  setHash:number;
}


export interface RangeI{
  start:number;
  end:number;
  color:RGBColor;
  opacity:number;
}


export interface ChartSettingsI{
  title:string;
  plot:boolean;
  width:number;
  height:number;
  automaticDomain:boolean;
  domain:[number,number];
  domainLabel:string;
  automaticRange:boolean;
  range:[number,number];
  rangeLabel:string;
  enableMinMax:boolean;
  enableMean:boolean;
  enableMedian:boolean;
  enableOutliers:boolean;
  enableCO2Ranges:boolean;
  gridX:number;
  gridY:number;
}


// export interface ChartSensorSettingsI{
//   title:string;
//   axisLabel:string;
//   automaticRange:boolean;
//   range:[number,number];
//   plot:boolean;
// }



type Vec = [number,number];

export function Chart(props:ChartI){
  const {sets,config, ranges, sensorIndex,setHash} = props;

  const [canvas,setCanvas] = useState<SVGElement|null>(null);


  const [loading,setLoading] = useState(true);
  const [view,setView] = useState<ProcessedGraphView|null>(null)

  let {width,height} = config;
  
  const styles = {
    base:{
        marginTop:20,
        marginBottom:20
    },
    label:{
      textAlign:"center",
      fontWeight:"bold"
    } as React.CSSProperties,
    imgHolder:{
      textAlign:"center"
    } as React.CSSProperties,
    img:{
      width:"100%"
    },
    svg:{
        width:width,
        height:height
    }
  }

  
  let xGutter = [100,10];
  let yGutter = [100,60];

  
  let bodySize:Vec = [width-xGutter[0]-xGutter[1], height-yGutter[0]-yGutter[1] ];
  let xAxisPosition:Vec = [xGutter[0],height-yGutter[1]];
  let xAxisSize:Vec = [bodySize[0],yGutter[0]];
  
  let yAxisPosition:Vec = [0,yGutter[0]];
  let yAxisSize:Vec = [xGutter[0], bodySize[1]];

  let bodyPosition:Vec = [xGutter[0],yGutter[0]];


  let titlePosition:Vec = [width/2,yGutter[0]/2];
  let yAxisTitlePosition:Vec = [10,height/2];
  let xAxisTitlePosition:Vec = [width/2,height-10];




  useEffect( () => {
    if(sets.length > 0){
      // console.log("processing buffer at chart");
      setLoading(true);
      let processed = processGraphConfig(sensorIndex,sets,config.domain,config.width);
      // console.log(processed);
      setView(processed);
      setLoading(false);
    }else{
      setView(null);
    }
  },[setHash]);



  if(view == null)
    return null;
  else   if(loading){
    return <div><CircularProgress color="primary" /></div>
  }else{
    return <svg style={styles.svg} ref={(r) => setCanvas(r)}>

{ranges.map( (range,i) => <PlotRange key={`range-${i}`} position={bodyPosition} size={bodySize} config={config}  range={range} />  )}


        <Grid position={bodyPosition} size={bodySize} config={config} />

        {config.enableMedian?   [...Array(view.setCount)].map( (v,i) => <PlotLine key={`median-${i}`} view={view} setIndex={i} index={"median"} position={bodyPosition} size={bodySize} config={config} /> ):null}
        {config.enableMean?  [...Array(view.setCount)].map( (v,i) => <PlotLine key={`mean-${i}`} view={view}  setIndex={i} index={"mean"} position={bodyPosition} size={bodySize} config={config} /> ):null}
        {config.enableMinMax?  [...Array(view.setCount)].map( (v,i) => <PlotArea key={`minmax-${i}`} view={view}  setIndex={i} position={bodyPosition} size={bodySize}  config={config} /> ):null}


      {config.enableOutliers? [...Array(view.setCount)].map( (v,i) => <PlotOutliers key={`outliers-${i}`}  view={view}  setIndex={i}  position={bodyPosition} size={bodySize} config={config} /> ):null}


        <XAxisLabels position={yAxisPosition} size={yAxisSize} config={config}  />
        <YAxisLabels position={xAxisPosition} size={xAxisSize} config={config}  />
        <XAxis position={xAxisPosition} size={xAxisSize} />
        <YAxis position={yAxisPosition} size={yAxisSize} />

        <ChartTitle position={titlePosition} size={xAxisSize} config={config} />
        
        <YAxisTitle position={yAxisTitlePosition} size={xAxisSize} config={config}  />
        <XAxisTitle position={xAxisTitlePosition} size={xAxisSize} config={config} />
    


    </svg>
  }

}

/*



*/



interface ChartTitleI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;
}

function ChartTitle(props:ChartTitleI){
  const {position,size,config} = props;

  let styles = {
    text:{
      stroke:"none",
      fill:"#CCC",
      dominantBaseline:"middle",
      textAnchor:"middle",
      fontSize:25

    } as React.CSSProperties
  };
  let [x,y] = position;
  let [width,height] = size;

  let title = config.title;

  return <g>
      <text  x={x} y={y} style={styles.text} >{title}</text>

  </g>

}



interface YAxisTitleI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;
}

function YAxisTitle(props:YAxisTitleI){
  const {position,size,config} = props;

  let styles = {
    text:{
      stroke:"none",
      fill:"#CCC",
      dominantBaseline:"middle",
      textAnchor:"middle" 

    } as React.CSSProperties
  };
  let [x,y] = position;
  let [width,height] = size;

  let axisLabel = config.rangeLabel;

  return <g transform={`translate(${x} ${y}), rotate(-90)`} >
      <text x={0} y={0} style={styles.text} >{axisLabel}</text>
  </g>
}


interface XAxisTitleI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;
}

function XAxisTitle(props:XAxisTitleI){
  const {position,size,config} = props;

  let styles = {
    text:{
      stroke:"none",
      fill:"#CCC",
      dominantBaseline:"middle",
      textAnchor:"middle" 

    } as React.CSSProperties
  };
  let [x,y] = position;
  let [width,height] = size;

  let axisLabel = config.domainLabel;

  return <g transform={`translate(${x} ${y}), rotate(0)`} >
      <text x={0} y={0} style={styles.text} >{axisLabel}</text>
  </g>

}


interface XAxisLabelsI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;
}

function XAxisLabels(props:XAxisLabelsI){
  const {position,size,config} = props;

  let styles = {
    text:{
      stroke:"none",
      fill:"#CCC",
      dominantBaseline:"middle",
      textAnchor:"end" 

    } as React.CSSProperties
  };
  
  let [x,y] = position;
  let [width,height] = size;
  let dY =  height/config.gridX;
  let range = config.range;
  let valueRange = range[1] - range[0];   //config.domain[1] - config.domain[0];
  let dVY = valueRange/config.gridX;

  let labels:any = [];
  for(let y = 0; y <= config.gridX; y ++){
    let v = Math.round(( range[0] + dVY*y)*10 )/10;
    labels.push(<text key={`x-axis-${y}`} x={width-10} y={height-y*dY} style={styles.text} >{v}</text>)
  }

  return <g transform={`translate(${x},${y})`}>    
    {labels}
  </g>


}



interface YAxisLabelsI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;
}

function YAxisLabels(props:YAxisLabelsI){
  const {position,size,config} = props;

  let styles = {
    text:{
      stroke:"none",
      fill:"#CCC",
      dominantBaseline:"hanging",
      textAnchor:"middle" 

    } as React.CSSProperties
  };
  
  let [x,y] = position;
  let [width,height] = size;
  let dX =  width/config.gridY;

  let domain = config.domain;
  let duration = domain[1]-domain[0];
  let dVX = duration/config.gridY;

  let labels:any = [];
  for(let x = 0; x <= config.gridY; x++){
    let v =  (domain[0] + dVX*x )*1000;
    let date = moment(v).format("DD/MM/YY");
    labels.push(<text key={`y-axis-${x}`} x={x*dX} y={10} style={styles.text} >{date}</text>)
  }

  return <g transform={`translate(${x},${y})`}>    
    {labels}
  </g>


}



interface GridI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;

}




function Grid(props:GridI){

  const {position,size,config} = props;



  return <g>
    <GridX position={position} size={size}  config={config}  />
    <GridY position={position} size={size}  config={config} />
  </g>
}



interface GridXI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;

}

function GridX(props:GridXI){
  const {position,size,config} = props;

  let styles = {
    line:{
      strokeWidth:1,
      stroke:"#CCC",
      fill:"none"
    }
  }
  
  let pathD = `M 0 0 `;
  let [x,y] = position;
  let [width,height] = size;
  let dY =  height/config.gridX;

  for(let y = 0; y < height; y += dY){
    pathD += `L 0 ${y} L ${width} ${y} L ${width} ${y+dY}`
  }

  return <g transform={`translate(${x},${y})`}>    
    <path d={pathD} style={styles.line} mask="url(#myMask)" />
  </g>
}


interface GridYI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;

}

function GridY(props:GridYI){
  const {position,size,config} = props;

  let styles = {
    line:{
      strokeWidth:1,
      stroke:"#CCC",
      fill:"none"
    }
  }
  
  let pathD = `M 0 0 `;
  let [x,y] = position;
  let [width,height] = size;
  let dX =  width/config.gridY;

  for(let x = 0; x < width; x += dX){
    pathD += `L ${x} 0 L ${x} ${height} L ${x+dX} ${height}`;
  }

  return <g transform={`translate(${x},${y})`}>    
    <path d={pathD} style={styles.line} mask="url(#myMask)" />
  </g>
}




interface XAxisI{
  position:Vec;
  size:Vec;
};

function XAxis(props:XAxisI){
  const {position,size} = props;

  let style = {
    strokeWidth:2,
    stroke:"#CCC"
  }

  let x1 = position[0];
  let x2 = x1+size[0];
  let y1 = position[1];
  let y2 = position[1];
  
  return <line x1={x1} x2={x2} y1={y1} y2={y2} style={style} />
}



interface YAxisI{
  position:Vec;
  size:Vec;
};

function YAxis(props:YAxisI){
  const {position,size} = props;

  let style = {
    strokeWidth:2,
    stroke:"#CCC"
  }

  let x1 = position[0]+size[0];
  let x2 = position[0]+size[0];
  let y1 = position[1];
  let y2 = position[1]+size[1];
  
  return <line x1={x1} x2={x2} y1={y1} y2={y2} style={style} />
}




interface PlotRangesI{
  position:Vec;
  size:Vec;
  config:ChartSettingsI;
  range:RangeI;
}

function PlotRange(props:PlotRangesI){
  const {position,size,config,range} = props;

  let styles = {
    body:{
      strokeWidth:0,
      stroke:"none"//,
      //fill:"black"
    },
    mask:{
      strokeWidth:0,
      stroke:"none",
      fill:"white"
    },
  };

  let [x,y] = position;
  let [width,height] = size;

  if(config.domain[1] == undefined || config.domain[0] == undefined)
    return null;
  
  let chartRange = config.range;
  let domain = config.domain;
  let duration = (config.domain[1] - config.domain[0]);
  
  
  let dy = height/(chartRange[1]-chartRange[0]);

  //height-o*dy + range[0]*dy;

  let start = range.start * dy 
  let end = range.end * dy 
  
  let band_x1 = 0;
  let band_x2 = width;
  
  let band_y1 = height-end + chartRange[0]*dy
  let band_y2 = height-start + chartRange[0]*dy

  let band_width = band_x2 - band_x1 ;
  let band_height = band_y2 - band_y1;

  let color = rgbToStrAlpha(range.color,range.opacity);


  return <g transform={`translate(${x},${y})`}>    
    <mask id="myMask">
      <rect  x={0} y={0} width={width} height={height} style={styles.body} fill="white" />
    </mask>

      <rect x={band_x1}  y={band_y1} height={band_height} width={band_width}  fill={color} mask="url(#myMask)"   />

    </g>
}





interface PlotLineI{
  position:Vec;
  size:Vec;
  setIndex:number,
  //set:GraphSetArrayBuffer,
  view:ProcessedGraphView
  config:ChartSettingsI;
  index:"median"|"mean";
}

function PlotLine(props:PlotLineI){
  const {index,position,size,setIndex,view,config} = props;

  let styles = {
    body:{
      strokeWidth:0,
      stroke:"none",
      fill:"white"
    },
    line:{
      strokeWidth:1,
      stroke:rgbToStr(view.colors[setIndex]),
      fill:"none"
    }
  };

 
  let [x,y] = position;
  let [width,height] = size;


  let range = config.range;
  let domain = config.domain;


  if(domain[0] == 0 && domain[1] == 0)
    return null;
  
  let duration = (domain[1] - domain[0]);
  

  let statIndex = index=="median"?StatArrayEnum.median:StatArrayEnum.mean
  
  let dx = width/view.width;
  let dy = height/(range[1]-range[0]);

  let f = view.getStatsTime(0,setIndex,statIndex);

  let pathD = `M ${0*dx} ${height-f*dy}`

  for(let i = 1; i < view.width; i++){
    let x = i*dx;
    let v = view.getStatsTime(i,setIndex,statIndex);
    let y = height - v*dy + range[0]*dy;

    let lastCount = view.getStatsTime(i-1,setIndex,StatArrayEnum.count);
    let thisCount = view.getStatsTime(i,setIndex,StatArrayEnum.count);

    let symbol = "M";
    if(thisCount > 0 && lastCount > 0)
      symbol = "L";

    pathD += ` ${symbol} ${x} ${y}`;
  }

  return <g transform={`translate(${x},${y})`}>
     <mask id="myMask">
        <rect x={0} y={0} width={width} height={height} style={styles.body} fill="white" />
    </mask>




    <path d={pathD} style={styles.line} mask="url(#myMask)" />
    </g>
}




interface PlotAreaI{
  position:Vec;
  size:Vec;
  setIndex:number,
  // set:GraphSetArrayBuffer,
  view:ProcessedGraphView
  config:ChartSettingsI;
}


function PlotArea(props:PlotAreaI){
  const {position,size,config,view,setIndex} = props;

  let styles = {
    body:{
      strokeWidth:0,
      stroke:"none",
      fill:"black"
    },
    line:{
      strokeWidth:0,
      stroke:"none",
      fill:rgbToStrAlpha(view.colors[setIndex],0.2)
    }
  };

  let [x,y] = position;
  let [width,height] = size;

  if(config.domain[1] == undefined || config.domain[0] == undefined)
    return null;
  
  let range = config.range;
  

  let dx =  width/view.width;
  let dy = height/(range[1]-range[0]);

  let f = view.getStatsTime(0,setIndex,StatArrayEnum.min);

  let pathD = `M ${0*dx} ${height-f*dy}`

  for(let i = 1; i < view.width; i++){
    let x = i*dx;
    let v = view.getStatsTime(i,setIndex,StatArrayEnum.min);
    let y = height - v*dy + range[0]*dy;

    pathD += ` L ${x} ${y}`;
  }

  for(let i = view.width-1; i >=0; i--){
    let x = i*dx;
    let v = view.getStatsTime(i,setIndex,StatArrayEnum.max);
    let y = height - v*dy + range[0]*dy;
    // console.log(i,v,y);

    pathD += ` L ${x} ${y}`;
  }

  pathD += ` Z`;

  

  return <g transform={`translate(${x},${y})`}>    
    <mask id="myMask">
      <rect x={0} y={0} width={width} height={height} style={styles.body} fill="white" />
    </mask>

    <path d={pathD} style={styles.line} mask="url(#myMask)" />

    </g>
}




interface PlotOutliersI{
  position:Vec;
  size:Vec;
  setIndex:number,
  view:ProcessedGraphView
  config:ChartSettingsI;
}

function PlotOutliers(props:PlotOutliersI){
  const {position,size,setIndex,view,config} = props;

  let styles = {
    body:{
      strokeWidth:1,
      stroke:"#CCC",
      fill:"rgba(0,0,0,0.1)"
    },
    circle:{
      strokeWidth:0,
      fill:rgbToStr(view.colors[setIndex]),
      stroke:"none"
    }
  };


  let [x,y] = position;
  let [width,height] = size;

  let range = config.range;
  let domain = config.domain;

  if(domain[0] == 0 && domain[1] == 0)
    return null;
  
  let duration = (domain[1] - domain[0]);
  

  let dx =  width/view.width;
  let dy = height/(range[1]-range[0]);
  let marks:any[] = [];

  for(let i = 0; i < view.width; i++){
    let x = i*dx; 
    let outliers = view.getOutliers(i,setIndex);

    for(let j = 0; j < outliers.length; j++){
      let o = outliers[j];
      let y = height-o*dy + range[0]*dy;
      marks.push(<circle key={`${setIndex}-${i}-${j}`} style={styles.circle} r={2} cx={x} cy={y} mask="url(#myMask)" />)
    }


  }
  

  return <g transform={`translate(${x},${y})`}>
     <mask id="myMask">
      <rect x={0} y={0} width={width} height={height} style={styles.body} fill="white" />
    </mask>

    {marks}

    </g>
}
