
import {  readFileText } from './CSV';
import {  uploadFiles } from './File';
import { GraphSetArrayBuffer} from "./ArrayBufferChart";
import { testArrayBufferWorker } from './workers';


export interface FileStringI{
  name:string;
  data:string;
}


  export async function importFilesForWorker(concatSets:(sets:GraphSetArrayBuffer[])=>void,setProgress:(progress:number)=>void){
    setProgress(0);
    try{
        let files = await uploadFiles(".csv");
        // console.log(files);
        //let sets = [];

        let data:FileStringI[] = [];
        for(let i = 0; i < files.length; i++){ //file of files){
            let file = files[i];
            let text = await readFileText(file)
            data.push({name:file.name,data:text})
        }


        let abs = [];

        for(let i = 0; i < data.length; i++){
          let file = data[i]; //file of data){
          abs.push(await testArrayBufferWorker(file,(v) => {}));

          setProgress((i*100/data.length));
        }

        let gs = abs.map( ab => new GraphSetArrayBuffer(ab)  );


      // console.log("gs",gs);
        concatSets(gs);
        setProgress(100);
    }catch(E){
      console.error(E);
    }
    
  }


  /*

  export async function processFileWorker(filename:string,csv:any[],setProgress:(progress:number)=>void):Promise<GraphSetI>{
        console.log("processFileWorker");
 

    let rows:any[] = [];

    for(let i = 0; i < csv.length; i++)
        rows.push(importRow(csv[i]));

    let rate = 60; //1 min
    let startTime = rows[0].timestamp;
    let endTime = rows[rows.length-1].timestamp+rate;
    let diff = endTime-startTime;
    
    let count = Math.ceil(Math.log2(diff/rate));

    //let windows:StatSample[][] = [];
    let windows:PageSamples[][] = [];
    let r = rate;
    let i = 0;

    function updateProgress(index:number,total:number){
        setProgress((i+(index/total))*100/count);
    }


    //let overallCount = 0;

    while(r <= diff){
      windows.push(flattenStatistics(rows,startTime+rate,endTime,r,updateProgress));
      r *= 2;
      i++;
    }

//    console.log("overall",i,count,Math.sqrt(diff));

    setProgress(100);

    //e = Date.now();
    //console.log("flatten",e-s);

    
    //s = Date.now();
    //let set = new GraphSet(filename, windows, startTime, rate);

    //e = Date.now();
    //console.log("GraphSet",e-s);


    //return set;


    let set:GraphSetI = {
      label:filename,
      windows:windows,
      timestamp:startTime,
      rate:rate
      
    }


    return set;
  }

  // label:string;
  // windows:PageSamples[][];
  // timestamp:number;
  // rate:number;
  

export function importRow(row:any){
  return {
    timestamp:parseInt(row.time),
    temperature:parseFloat(row.temperature),
    humidity:parseFloat(row.humidity),
    co2:parseFloat(row.co2)
  };
}


*/