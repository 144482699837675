
export function downloadTextFile(data:string,filename:string){
    var charset = "text;charset=utf-8;";
    var csvFile = data;
    var blob = new Blob([csvFile], { type: charset });
    //if (navigator.msSaveBlob) { // IE 10+
      //  navigator.msSaveBlob(blob, filename);
    //} else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
  
            var newContent = document.createTextNode(filename); 
            link.appendChild(newContent);
            document.body.appendChild(link);
            link.click();
           document.body.removeChild(link);
        }
    //}
  }
  
  

  async function blobToBase64(blob:Blob):Promise<string>{
    return new Promise((resolve,reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            resolve(reader.result as string);
        }
    });
  }


export async function downloadBlobFile(data:ArrayBuffer,filename:string){

    var blob = new Blob([data]); //  { type: charset }

   //if (navigator.msSaveBlob) { // IE 10+
     //   navigator.msSaveBlob(blob, filename);
    //} else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
  
            var newContent = document.createTextNode(filename); 
            link.appendChild(newContent);
            document.body.appendChild(link);
            link.click();
           document.body.removeChild(link);
        }
    //}
    
  }
  

  
  
  
  export function downloadFileURL(url:string,filename:string){
  
          var link = document.createElement("a");
          if (link.download !== undefined) {
       
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
    
              var newContent = document.createTextNode(filename); 
              link.appendChild(newContent);
              document.body.appendChild(link);
              link.click();
             document.body.removeChild(link);
          }
      
    }


      
  export async function uploadFile(accept?:string,capture?:string):Promise<File>{
    //   console.log("uploadFile");
         return new Promise( (resolve,reject) => {

            // console.log("promise");
            
            let link = document.createElement("input");
            link.setAttribute("type", "file");
            
            if(typeof accept != "undefined")
                link.setAttribute("accept",accept);
            if(typeof capture != "undefined")
                link.setAttribute("capture",capture);


                window.addEventListener("focus", (e: Event) => {
                    setTimeout(() => {
                        //console.log("timeout");
                        try{
                            document.body.removeChild(link);
                        }catch(E){

                        }
                    },500);
                }, { once: true });
          
            link.addEventListener("change", (e: Event) => {
                 console.log(e);
                if(e == null || e.target == null){
                    reject("Null event");
                    document.body.removeChild(link);
                }else{
                    const files = (e.target as HTMLInputElement).files;
                    // console.log(files);
                   if(files == null){
                        reject("No file") 
                        document.body.removeChild(link);
                   }else if(files.length != 1){
                        reject("No file");
                        document.body.removeChild(link);
                   }else{
                       let file = files[0];
                        resolve(file);
                        document.body.removeChild(link);
                   }
                }
            });
            document.body.appendChild(link);
            link.click();
          

            // console.log("link clicked");
        })

    }



  export async function uploadFiles(accept?:string,capture?:string):Promise<File[]>{
    //   console.log("uploadFile");
         return new Promise( (resolve,reject) => {

            // console.log("promise");
            
            let link = document.createElement("input");
            link.setAttribute("type", "file");
            
            if(typeof accept != "undefined")
                link.setAttribute("accept",accept);
            if(typeof capture != "undefined")
                link.setAttribute("capture",capture);

            link.setAttribute("multiple","multiple");

                window.addEventListener("focus", (e: Event) => {
                    setTimeout(() => {
                        //console.log("timeout");
                        try{
                            document.body.removeChild(link);
                        }catch(E){

                        }
                    },500);
                }, { once: true });
          
            link.addEventListener("change", (e: Event) => {
                 //console.log(e);
                if(e == null || e.target == null){
                    reject("Null event");
                    document.body.removeChild(link);
                }else{
                    const files = (e.target as HTMLInputElement).files;
                    // console.log(files);
                   if(files == null){
                        reject("No file") 
                        document.body.removeChild(link);
                   }else{
                       let f:File[] = [];
                        for(let i = 0; i < files.length; i++)
                            f.push(files[i]);
                        resolve(f);
                        document.body.removeChild(link);
                   }
                }
            });
            document.body.appendChild(link);
            link.click();
          

            // console.log("link clicked");
        })

    }



 export function createBlankLink(href:string){
        let a = document.createElement("a")
        a.href =  href;
        a.target = "_blank";
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }    
