import { AppBar, Toolbar } from "@mui/material";

 
 
 
 export function Header(){
 
    return <AppBar position="static">
        <Toolbar>
            Airtopia Charts
        </Toolbar>
    </AppBar>

}